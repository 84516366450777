import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IFooterProps {
}

export interface IFooterState {
  isNavbarTogglerActive: boolean;
  subMenuActive: boolean;
  isTop: boolean;
}

export default class Footer extends React.Component<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props);

    this.state = {
      isTop: true,
      isNavbarTogglerActive: false,
      subMenuActive: false,


    }
   // Bind methods to the component instance
   this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
   this.handleNavItemClick = this.handleNavItemClick.bind(this);
   this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
   this.handleDocumentClick = this.handleDocumentClick.bind(this);

   this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', this.handleScroll);
    // Add click event listener to close the menu when clicking outside of it
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  componentWillUnmount() {
    // Remove scroll and click event listeners when the component is about to unmount
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleDocumentClick);
  }
  handleNavbarToggle() {
    this.setState((prevState) => ({
      isNavbarTogglerActive: !prevState.isNavbarTogglerActive,
    }));
  }

  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }

  handleSubmenuClick(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      subMenuActive: !prevState.subMenuActive,
    }));
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    this.setState({ isTop: scrollPosition === 0 });
  }

  handleDocumentClick(event: MouseEvent) {
    const navbar = document.getElementById('navbarCollapse');
    const navbarToggler = document.getElementById('navbarToggler');
  
    // If the click is outside the navbar and the navbar toggler is not clicked
    if (navbar && navbarToggler && !navbar.contains(event.target as Node) && !navbarToggler.contains(event.target as Node)) {
      this.setState({
        isNavbarTogglerActive: false,
        subMenuActive: false,
      });
    }
  }

  public render() {
    return (
        // foter alwasy at the bottom
    <div>
      
          <footer className="bg-gray-800 text-white py-6 mt-auto z-10 ">
          <div className=''>

      <div className="container mx-auto flex flex-wrap justify-center">

            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4">
              <h4 className="text-lg font-semibold mb-3">Nützliche Links</h4>
              <ul className="flex flex-col lg:flex-wrap lg:justify-between lg:space-y-1">
                {/* Add links here */}
                <li className="group relative">
                <NavLink to="/home">
                  <button
                  onClick={this.handleNavItemClick} 
                    className="ud-menu-scroll mx-8 flex font-medium text-base text-white group-hover:text-primary lg:mr-0 lg:inline-flex lg:px-0 lg:text-white lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                    Home
                    </button>
                </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/aboutus">

                  <button
                  onClick={this.handleNavItemClick}
                  className="ud-menu-scroll mx-8 flex font-medium text-base text-white group-hover:text-primary lg:mr-0 lg:inline-flex  lg:px-0 lg:text-white lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                  Über uns
                  </button>
                  </NavLink>
                </li>              
                <li className="group relative">
                <NavLink to="/ourprojects">
                  <button
                  onClick={this.handleNavItemClick}
                  className="ud-menu-scroll mx-8 flex font-medium text-base text-white group-hover:text-primary lg:mr-0 lg:inline-flex  lg:px-0 lg:text-white lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                    Unsere Projekte
                  </button>
                  </NavLink>
                </li>           
                <li className="group relative">
                <NavLink to="/contact">
                  <button
                  onClick={this.handleNavItemClick}
                  className="ud-menu-scroll mx-8 flex font-medium text-base text-white group-hover:text-primary lg:mr-0 lg:inline-flex  lg:px-0 lg:text-white lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                    Kontakt
                  </button>
                  </NavLink>
                </li>
                
              </ul>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4">
              <h4 className="text-lg font-semibold mb-3">Dienstleistungen</h4>
              <ul className="flex flex-col lg:flex-wrap lg:justify-between lg:space-y-1">
                {/* Add your products list items here */}
                <li>Photovoltaik-Anlagenplanung</li>
                <li>Montage und Installation</li>
                <li>Wartung und Reparatur</li>
                <li>Leistungsoptimierung</li>
              </ul>
            </div>
    
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4">
              
              <h4 className="text-lg font-semibold mb-3">Öffnungszeiten</h4>
              <ul className="flex flex-col lg:flex-wrap lg:justify-between lg:space-y-1">
                <li><strong>Montag:</strong> 8:00 - 16:00</li>
                <li><strong>Dienstag:</strong> 8:00 - 16:00</li>
                <li><strong>Mittwoch:</strong> 8:00 - 16:00</li>
                <li><strong>Donnerstag:</strong> 8:00 - 16:00</li>
                <li><strong>Freitag:</strong> 8:00 - 14:00</li>
             
              </ul>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
            <NavLink to="/home">

            <img src="../../../img/logo/logoHome.png" alt="logo"  />
            </NavLink>
            </div>
            <hr className="w-full border-gray-700 my-6" />

            </div>
          <div className='container mx-auto flex flex-wrap'>
          <div className="lg:w-1/2">
              
              <h4 className="text-lg font-semibold mb-3">Kontakt</h4>
              <ul className="flex flex-col lg:flex-wrap lg:justify-between lg:space-y-1">
                <li> <a href='tel:+436769212103'><strong>Telefon : </strong>+436769212103</a> </li>
                <li><a href='mailto:info@rnz-photovoltaik.at'><strong>Email : </strong>info@rnz-photovoltaik.at</a></li>
                <li><strong>Adresse : </strong> Richard-Neutra-Gasse 5/215, 1210 Wien</li>

              </ul>

            </div>
            <div className="lg:w-1/2">
              
              <h4 className="text-lg font-semibold mb-3">Sozialen Medien</h4>
              <ul className="flex flex-col lg:flex-wrap lg:justify-between lg:space-y-1">
                <li><a href="https://www.facebook.com/rnzphotovoltaik"><strong>Facebook</strong></a></li>
                <li><a href="https://www.instagram.com/rnzphotovoltaik/"><strong>Instagram</strong></a></li>
              </ul>
            </div>

          </div>
            <p className='text-center'>  &copy; {new Date().getFullYear()} RNZ-photovoltaik.at</p>
            <div className="w-full">
              <ul className="flex justify-center space-x-4">
                <li>
                <NavLink to="/impressum">
                  <button
                  onClick={this.handleNavItemClick}
                  className="text-blue-300">
                    Impressum
                  </button>
                  </NavLink>
               </li>
                <li><a href="#" className="text-blue-300">Privacy</a></li>
              </ul>
            </div>
            
          </div>
        </footer>
    </div>
  
    );  
    }
}
