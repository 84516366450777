import * as React from 'react';
import Cookies from 'js-cookie';

export interface ICookiescomponentProps {
}

export interface ICookiescomponentState {
}

export default class Cookiescomponent extends React.Component<ICookiescomponentProps, ICookiescomponentState> {
  constructor(props: ICookiescomponentProps) {
    super(props);

    this.state = {
    }
  }


  public render() {
    return (
      <div>
      {/** ask user to accept to save cookies  */}
        <div className={`
        ${Cookies.get('cookie') === 'true' ? 'hidden' : 'block'}
        fixed bottom-0 w-full bg-white shadow-lg z-50 transition duration-500 ease-in-out`}>
            <div className="container mx-auto py-4 px-4">
                <div className="flex items-center justify-between">
                <div>
                    <p className="text-sm text-gray-800">Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website zu verbessern. Durch das Surfen auf dieser Website stimmen Sie der Verwendung von Cookies zu.</p>
                </div>
                <div>
                    <button
                    onClick={() => {
                        Cookies.set('cookie', 'true', { expires: 365 });
                        window.location.reload();

                    }}
                    className="bg-blue-600 hover:bg-blue-400 text-white px-4 py-2 rounded-md"
                    >
                    Akzeptieren
                    </button>
                </div>
                </div>
            </div>
        </div>
        </div>
    );
    }
}